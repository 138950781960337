<script>
import ENUMS from '@shared/messages/enums.json';
import TrainingShareDropdown from '@app/components/TrainingShareDropdown.vue';

export default {
  components: { TrainingShareDropdown },
  messages: {
    status: ENUMS.trainingStatus,
    statusClass: ENUMS.trainingStatusClass,
  },
  props: {
    training: {
      type: Object,
      required: true,
    },
    reorderable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    schoolUrl() {
      const q = `?p=${this.$store.state.auth.previewToken}`;
      return `${this.$store.getters['auth/url']}/mon-espace/formations/${this.training.slug}${q}`;
    },
    salesUrl() {
      const { protocol } = window.location;
      return `${protocol}${this.$store.getters['auth/url']}/formations/${this.training.slug}`;
    },
  },
  methods: {
    updateStatus(status) {
      if (this.training.status === status) {
        return;
      }

      if (['UNLISTED', 'HIDDEN'].includes(status) && this.$store.getters['auth/hasPercentPlan']) {
        this.$showMessage.goPro();
        return;
      }

      const loader = this.$buefy.loading.open();
      this.$store.dispatch('trainings/update', {
        uuid: this.training.uuid,
        training: { status },
      })
        .finally(() => loader.close());
    },
    deleteItem() {
      this.$buefy.dialog.confirm({
        title: 'Cette action est définitive !',
        message: `
          <div class="content">
            <p>
              <strong>Attention</strong> !
              Vous allez supprimer la formation
              <strong>${this.training.name}</strong>.
            </p>
            <p>
              Tous les contenus, chapitres, leçons, quiz et
              commentaires liés à cette formation seront
              <strong>définitivement supprimés</strong>.
            </p>
            <p>Les apprenants n'y auront plus accès.
              Leurs logs de connexion seront
              également <strong>supprimés</strong>.
            </p>
            <p class="has-text-danger has-text-weight-bold">
              Cette action est définitive !
            </p>
          </div>
        `,
        type: 'is-danger',
        focusOn: 'cancel',
        onConfirm: () => {
          const loader = this.$buefy.loading.open();
          this.$store.dispatch('trainings/remove', this.training)
            .finally(() => loader.close());
        },
      });
    },
    duplicate() {
      if (this.$store.getters['auth/hasPercentPlan']) {
        this.$showMessage.goPro(() => {
          this.$router.push({ name: 'plan', query: { onglet: 'vous' } });
        });
        return;
      }

      this.$buefy.dialog.confirm({
        message: 'Êtes-vous sûr(e) de vouloir dupliquer cette formation ?',
        type: 'is-success',
        confirmText: 'Oui !',
        focusOn: 'cancel',
        onConfirm: () => {
          const loader = this.$buefy.loading.open();
          this.$store.dispatch('trainings/duplicate', this.training)
            .then(() => {
              this.$showMessage.success();
              window.scroll(0, 0);
            })
            .finally(() => loader.close());
        },
      });
    },
  },
};
</script>

<template>
  <div class="tscomp box pb-0 pr-0 overflow-hidden">
    <article class="media">
      <b-button v-if="reorderable" class="handle media-left is-hidden-mobile" type="is-text" icon-left="grip-lines"
        title="Cliquez et glissez pour réodonner" />
      <router-link class="media-left image is-128x128 cursor-pointer bradius-8 is-hidden-mobile"
        :class="{ 'has-background-grey': !training.picture }"
        :to="{ name: 'training', params: { uuid: training.uuid } }" tag="figure">
        <img v-if="training.picture" class="ofit-cover bradius-8" :style="{ width: '128px', height: '128px' }"
          :src="training.picture" :alt="training.name">
      </router-link>
      <div class="media-content">
        <p class="is-size-8 mb-3">
          <span class="tag mr-3">
            <b-icon class="mr-1 is-size-9" icon="circle" :type="$options.messages.statusClass[training.status]" />
            {{ $options.messages.status[training.status] }}
          </span>
          <span>
            <time>Créée le <strong>{{ training.created_at | momentFromUTC | moment('DD/MM/YY') }}</strong></time>

            <span class="mx-1">•</span>

            <time>Modifiée le <strong>{{ training.updated_at | momentFromUTC | moment('DD/MM/YY') }}</strong></time>
          </span>
        </p>
        <h2 class="is-size-5 has-text-weight-bold">
          <router-link class="has-text-black" :to="{ name: 'training', params: { uuid: training.uuid } }">
            {{ training.name }}
          </router-link>
        </h2>

        <div class="is-flex pr-5 mt-7" style="gap: 6px;">
          <div v-for="(tag, index) in training.tags" :key="index" @click="$emit('selected-tag', { tag })">
            <b-tag type="is-primary" style="cursor:pointer;">
              {{ tag }}
            </b-tag>
          </div>
        </div>
      </div>
      <nav class="media-right buttons is-align-self-center">
        <b-button tag="router-link" :to="{ name: 'training', params: { uuid: training.uuid } }">
          Modifier
        </b-button>
        <b-dropdown position="is-bottom-left" aria-role="menu" append-to-body>
          <template #trigger>
            <b-button type="is-text" icon-left="eye" />
          </template>
          <b-dropdown-item has-link aria-role="menuitem">
            <a :href="salesUrl" target="_blank">
              Voir la page visiteur
            </a>
          </b-dropdown-item>
          <b-dropdown-item has-link aria-role="menuitem">
            <a :href="schoolUrl" target="_blank">
              Voir la page apprenant
            </a>
          </b-dropdown-item>
        </b-dropdown>
        <b-tooltip label="Dupliquer la formation" position="is-left">
          <b-button type="is-text" icon-left="copy" @click="duplicate" />
        </b-tooltip>
        <TrainingShareDropdown :training="training" position="is-bottom-left" aria-role="menu" append-to-body>
          <b-tooltip label="Partager la formation" position="is-left">
            <b-button type="is-text" icon-left="share-square" />
          </b-tooltip>
        </TrainingShareDropdown>
        <b-dropdown class="mr-3" position="is-bottom-left" aria-role="menu" append-to-body>
          <template #trigger>
            <b-button type="is-text" icon-left="ellipsis-h" />
          </template>
          <b-dropdown-item class="has-text-danger" has-link aria-role="menuitem">
            <router-link :to="{ name: 'training_settings', params: { uuid: training.uuid } }">
              Paramètres
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item separator />
          <b-dropdown-item aria-role="menuitem" @click="updateStatus('PUBLISHED')">
            <b-icon icon="circle" type="is-success" class="is-size-9" />
            Publier
          </b-dropdown-item>
          <b-dropdown-item aria-role="menuitem" @click="updateStatus('DRAFT')">
            <b-icon icon="circle" type="is-warning" class="is-size-9" />
            Mettre en brouillon
          </b-dropdown-item>
          <b-dropdown-item aria-role="menuitem" @click="updateStatus('UNLISTED')">
            <b-icon icon="circle" type="is-info" class="is-size-9" />
            Ne plus répertorier
            <AppFeatureBadge class="ml-2" position="is-left" />
          </b-dropdown-item>
          <b-dropdown-item aria-role="menuitem" @click="updateStatus('HIDDEN')">
            <b-icon icon="circle" type="is-dark" class="is-size-9" />
            Cacher
            <AppFeatureBadge class="ml-2" position="is-left" />
          </b-dropdown-item>
          <b-dropdown-item aria-role="menuitem" @click="updateStatus('CLOSED')">
            <b-icon icon="circle" type="is-danger" class="is-size-9" />
            Désactiver
          </b-dropdown-item>
          <b-dropdown-item separator />
          <b-dropdown-item class="has-text-danger" has-link aria-role="menuitem">
            <router-link :to="{ name: 'embeds', query: { formation: training.uuid } }">
              Intégrer en widget
            </router-link>
          </b-dropdown-item>
          <!-- <b-dropdown-item class="has-text-danger" has-link aria-role="menuitem">
            <router-link :to="{name: 'training_comments', params: {uuid: training.uuid}}">
              Partager
            </router-link>
          </b-dropdown-item> -->
          <b-dropdown-item separator />
          <b-dropdown-item class="has-text-danger" has-link aria-role="menuitem">
            <router-link :to="{ name: 'training_customers', params: { uuid: training.uuid } }">
              Apprenants
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item class="has-text-danger" has-link aria-role="menuitem">
            <router-link :to="{ name: 'training_comments', params: { uuid: training.uuid } }">
              Commentaires
            </router-link>
          </b-dropdown-item>
          <b-dropdown-item separator />
          <b-dropdown-item class="has-text-danger" aria-role="menuitem" @click="deleteItem">
            Supprimer
          </b-dropdown-item>
        </b-dropdown>
      </nav>
    </article>
    <footer class="-mx-5 mt-5 px-5 py-3 btop-grey">
      <p class="has-text-grey-dark">
        <span class="is-inline-block is-size-8">
          <b-icon icon="tag" />
          {{ training.price > 0 ? `${training.price} &euro;` : 'Gratuite' }}
        </span>
        <span class="is-inline-block is-size-8 ml-4">
          <b-icon icon="book" />
          {{ training.counters.nb_training_items }}
          {{ training.counters.nb_training_items > 1 ? 'leçons' : 'leçon' }}
        </span>
        <span v-if="training.status == 'PRE_LAUNCH'" class="is-inline-block is-size-8 ml-4">
          <b-icon icon="users" />
          {{ training.counters.nb_pre_launch }}
          {{ training.counters.nb_pre_launch > 1 ? 'pré-inscrits' : 'pré-inscrit' }}
        </span>
        <span v-else class="is-inline-block is-size-8 ml-4">
          <b-icon icon="users" />
          {{ training.counters.nb_customer }}
          {{ training.counters.nb_customer > 1 ? 'apprenants' : 'apprenant' }}
        </span>
        <span class="is-inline-block is-size-8 ml-4">
          <b-icon icon="cart-plus" />
          {{ training.counters.nb_abandoned_cart }}
          {{ training.counters.nb_abandoned_cart > 1 ? 'paniers abandonnés' : 'panier abandonné' }}
        </span>
      </p>
      <!-- <div class="is-flex pr-5" style="gap: 6px;">
        <b-tag
          v-for="(tag, index) in training.tags"
          :key="index"
          type="is-primary"
        >
          {{ tag }}
        </b-tag>
      </div> -->
    </footer>
  </div>
</template>

<style lang="scss" scoped>
.tscomp {
  @include mobile {
    padding-right: pxToRem(20px) !important;

    .media {
      flex-wrap: wrap;
    }

    .media-right {
      width: 100%;
      margin: pxToRem(20px) 0 0;
    }
  }
}

footer {
  display: flex;
  justify-content: space-between;
  gap: 12px;

  @include mobile {
    flex-direction: column;
  }
}
</style>
