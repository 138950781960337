<script>
import Draggable from 'vuedraggable';
import trainingsMixin from '@app/mixins/trainings';
import TrainingSingle from '@app/components/TrainingSingle.vue';
import TagInput from '@app/components/TagInput.vue';
import { debounce, isEqual } from 'lodash';

const DEFAULT_SEARCH_FORM_DATA = {
  q: '',
  tags: [],
  sort_by: '',
  order_by: 'asc',
  status: '',
};

export default {
  mixins: [trainingsMixin],
  components: {
    Draggable,
    TrainingSingle,
    TagInput,
  },
  head: {
    title: 'Vos Formations',
  },
  created() {
    this.isFetching = true;
    this.$store.commit('trainings/setSearchParams', this.searchFormData);
    this.$store.dispatch('trainings/count')
      .then(() => this.$store.dispatch('trainings/fetch'))
      .finally(() => (this.isFetching = false));
  },
  data: () => ({
    isFetching: false,
    searchFormData: { ...DEFAULT_SEARCH_FORM_DATA },
  }),
  computed: {
    count() {
      return this.$store.getters['trainings/count'];
    },
    trainings: {
      set(trainings) {
        this.$store.dispatch('trainings/reorder', trainings)
          .then(() => this.$showMessage.success());
      },
      get() {
        return (
          this.$store.state.trainings.list
          && this.$store.state.trainings.list.data
        );
      },
    },
    hasPercentPlan() {
      return this.$store.getters['auth/hasPercentPlan'];
    },
    authStore() {
      return this.$store.getters['auth/store'];
    },
    debouncedSearch() {
      return debounce(this.search, 300);
    },
    searchParamsDirty() {
      return !isEqual(DEFAULT_SEARCH_FORM_DATA, this.searchFormData);
    },
  },
  methods: {
    async search() {
      this.isFetching = true;
      await this.$store.commit('trainings/setSearchParams', this.searchFormData);
      await this.$store.dispatch('trainings/fetch');
      this.isFetching = false;
    },
    toggleOrder() {
      this.searchFormData.order_by = this.searchFormData.order_by !== 'desc' ? 'desc' : 'asc';
      this.search(true);
    },
    resetSearchParams() {
      return this.searchFormData = { ...DEFAULT_SEARCH_FORM_DATA };
    },
    handleSortByChange(value) {
      this.searchFormData.sort_by = value;
      if (['training_items_count', 'customers_count'].includes(value)) {
        return this.searchFormData.order_by = 'desc';
      }
      return this.searchFormData.order_by = 'asc';
    },
  },
  watch: {
    searchFormData: {
      handler() {
        this.debouncedSearch();
      },
      deep: true,
    },
  },
};
</script>

<template>
  <section>
    <header class="level mb-0">
      <div class="level-left is-block w-80p">
        <h1 class="title is-size-4-mobile mb-2 is-flex is-align-items-center">
          Vos formations
          <span v-if="isFetching" class="loader is-primary is-size-5 ml-4" />
          <template v-else-if="!searchParamsDirty">(
            <span v-if="authStore.capabilities.nb_trainings < 0">
              {{ count }} / ∞
            </span>
            <span v-else>
              {{ (trainings && trainings.length) || 0 }} / {{ authStore.capabilities.nb_trainings }}
            </span>
            )</template>
        </h1>
      </div>
      <div class="level-right is-hidden-mobile">
        <b-button type="is-primary" icon-left="plus" @click="addTraining">
          Ajouter une formation
        </b-button>
      </div>
    </header>
    <template v-if="count === null || trainings === null">
      <div>
        <b-skeleton height="200" />
        <b-skeleton height="200" />
        <b-skeleton height="200" />
      </div>
    </template>
    <template v-else-if="count <= 0">
      <EmptyState title="Vous n'avez pas encore de formation"
        text="Cliquez sur le bouton ci-dessous pour en créer une dès maintenant." cta-text="Ajouter une formation"
        :cta-func="addTraining" />
    </template>
    <template v-else>
      <h3 class="mb-8 is-size-8">
        L'ordre d'affichage par défaut de vos formations sera le même dans votre
        <a :href="$store.getters['auth/url']" target="_blank">
          page d'accueil
        </a>.
        <p v-if="!hasPercentPlan">
          Modifiez l'ordre des formations dans l'espace apprenant
          <router-link :to="{ name: 'customization_internal' }">
            ici
          </router-link>.
        </p>
      </h3>

      <form @submit.prevent="search" class="mb-5">
        <div class="columns" style="margin-bottom: 0;">
          <div class="column">
            <b-field label="Filtrer par">
              <b-input v-model="searchFormData.q" placeholder="Filtrer par nom ou description" :loading="isFetching" />
            </b-field>
          </div>

          <div class="column">
            <b-field label="Étiquette(s)">
              <TagInput v-model="searchFormData.tags" placeholder="Filtrer par étiquette" />
            </b-field>
          </div>
          <div class="column is-narrow">
            <b-field label="Statut">
              <b-select v-model="searchFormData.status">
                <option value="">Tous</option>
                <option value="DRAFT">En Brouillon</option>
                <option value="PUBLISHED">Publiée</option>
                <option value="UNLISTED">Non répertoriée</option>
                <option value="HIDDEN">Cachée</option>
                <option value="CLOSED">Désactivée</option>
              </b-select>
            </b-field>
          </div>

          <div class="column is-narrow">
            <b-field label="Trier par">
              <b-select :value="searchFormData.sort_by" @input="handleSortByChange">
                <option value="" disabled>Ordre d'affichage</option>
                <option value="created_at">Date de création</option>
                <option value="name">Nom</option>
                <option value="training_items_count">Nombre de leçons</option>
                <option value="customers_count">Nombre d'apprenants</option>
              </b-select>
              <p class="control">
                <b-tooltip :label="searchFormData.order_by == 'asc' ? 'croissant' : 'décroissant'">
                  <b-button :icon-left="searchFormData.order_by == 'asc' ? 'arrow-up' : 'arrow-down'"
                    @click="toggleOrder" />
                </b-tooltip>
              </p>
            </b-field>
          </div>
        </div>
      </form>

      <a v-if="searchParamsDirty" href="#" class="is-size-8 level-right mb-6" @click.prevent="resetSearchParams"
        style="cursor:pointer;">
        <strong>Réinitialiser les paramètres de recherche</strong>
      </a>

      <draggable v-if="trainings.length > 0" v-model="trainings" handle=".handle">
        <TrainingSingle v-for="training in trainings" :key="training.uuid" :training="training"
          :reorderable="!searchParamsDirty"
          @selected-tag="searchFormData.tags = [...searchFormData.tags, $event.tag]" />
      </draggable>

      <EmptyState v-else title="Aucun résultat trouvé" text="Aucune formation ne correspond à votre recherche."
        cta-text="Réinitialiser les paramètres de recherche" :cta-func="resetSearchParams" />
    </template>
  </section>
</template>
